.inline-toolbar .avatar-button {
  height: unset;
  margin-right: 22px;
}

.inline-toolbar ion-avatar {
  height: 36px;
  width: 36px;
}

@media screen and (min-width: 992px) {
  ion-title {
    padding-left: 1.3em;
    padding-right: 1.3em;
  }
}
