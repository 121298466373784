.remove-button {
  margin-right: 5px;
  margin-left: -10px;
}

.remove-button::part(native) {
  background-color: white;
  --background-activated: white;
  --background-focused: white;
  --background-hover: white;
}
