ion-toolbar {
  --border-style: none;
}

ion-content.schedule-page::part(scroll) {
  overflow: hidden;
  background: #fff5f5;
}

ion-content.schedule-page::part(background) {
  background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
}

.calendar ion-title {
  font-weight: 400;
}
