ion-datetime {
  --background: white;
  --wheel-highlight-background: lightgray;
  --wheel-fade-background-rgb: 256, 256, 256;
}

.hide-item-border::part(native) {
  --border-color: white;
}

.hide-active-color {
  --background-activated: white;
}
