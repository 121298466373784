@media screen and (min-width: 992px) {
  ion-tab-bar {
    display: none;
  }
}

ion-tab-button ion-icon {
  margin-top: 8px;
}

ion-tab-button {
  --color: #acacac;
  --color-selected: rgb(31 146 255);
}
