.list-header {
  color: var(--ion-color-medium);
  text-transform: uppercase;
  font-size: 0.7rem;
  padding: 20px 1rem 0;
}

.list-header.inset {
  padding: 20px 2rem -0.6rem;
}

.first-header {
  padding-top: 0;
}
